import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import color from "../../styles/colors";
import Container from "../Context/Container";
import typography from "../../styles/typography";
import Section from "../Context/Section";
import CustomLink from "../Elements/CustomLink";

const Title = styled.h2`
    ${typography.label};
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 16px;
    font-weight: 600;
    text-align: center;

    @media screen and (min-width: 768px) {
        margin-bottom: 24px;
    }
`;

const Wrapper = styled.div`
    border-top: ${props => props.topSpacing ? `1px solid ${color("grey")}` : "none"};
    display: flex;
    flex-direction: row;
    justify-content: center;

    ${props => props.topSpacing ? css`
        padding-top: 64px;

        @media screen and (min-width: 768px) {
            padding-top: 72px;
        }

        @media screen and (min-width: 1200px) {
            padding-top: 105px;
        }

        @media screen and (min-width: 1800px) {
            padding-top: 120px;
        }

        @media screen and (min-width: 2200px) {
            padding-top: 145px;
        }
    ` : null}
`;

const List = styled.ul`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: -8px -8px;
    height: ${props => props.height};
    overflow: hidden;
    transition: height 0.3s, margin 0.3s;
    margin-bottom: ${props => props.open ? "0" : "24px"};

    @media screen and (min-width: 768px) {
        margin-bottom: ${props => props.open ? "0" : "32px"};
    }
`;

const ListItem = styled.li`
    padding: 8px;
`;

const ListLink = styled(CustomLink)`
    ${typography.meta};
    display: inline-block;
    border: 1px solid ${color("dark")};
    border-radius: 100px;
    padding: 5px 16px;
    text-decoration: none;
    color: ${color("dark")};
    transition: border 0.2s, color 0.2s;

    &:hover {
        border-color: ${color("dark blue")};
        color: ${color("dark blue")};
    }
`;

const ViewAll = styled.div`
    text-align: center;
    height: ${props => props.height};
    overflow: hidden;
    transition: height 0.3s;
`;

const ViewAllButton = styled.button`
    ${typography.labelSmall};
    background: none;
    border: none;
    font-weight: 600;
    cursor: pointer;
    transition: color 0.2s;
    border-bottom: 2px solid ${color("dark")};
    padding: 0;

    &:hover {
        color: ${color("dark blue")};
    }

    &:focus {
        outline: none;
    }
`;

const TagsList = ({tags, border, bg, theme, sectionSibling, topSpacing}) => {
    const [open, setOpen] = useState(false);
    const [hideViewAllButton, setHideViewAllButton] = useState(false);
    const [closedHeight, setClosedHeight] = useState(0);
    const [openHeight, setOpenHeight] = useState(0);
    const [viewAllHeight, setViewAllHeight] = useState(0);

    const list = useRef();
    const viewAll = useRef();

    const calcOpenSize = useCallback(() => {
        const scrollHeight = list.current?.scrollHeight;
        setOpenHeight(Math.ceil(scrollHeight));

        return Math.ceil(scrollHeight);
    },[]);

    const calcClosedSize = useCallback(() => {
        const scrollHeightLink = list.current?.children[0]?.scrollHeight;
        setClosedHeight(Math.ceil(scrollHeightLink * 2));

        return Math.ceil(scrollHeightLink * 2);
    },[]);

    const calcViewAllSize = useCallback(() => {
        const scrollHeight = viewAll.current?.scrollHeight;
        setViewAllHeight(Math.ceil(scrollHeight) + 1);

        return Math.ceil(scrollHeight) + 1;
    },[]);

    const calcIsAlreadyOpen = useCallback((openSize, closedSize) => {
        if(closedSize >= openSize) {
            setHideViewAllButton(true);
        } else {
            setHideViewAllButton(false);
        }
    },[]);

    const setHeightAuto = () => {
        if(list.current) list.current.style.height = "auto";
        if(viewAll.current) viewAll.current.style.height = "auto";
    }

    const unsetHeightAuto = () => {
        if(list.current) list.current.style.removeProperty("height");
        if(viewAll.current) viewAll.current.style.removeProperty("height");
    }

    const sizeCalculations = useCallback(() => {
        setHeightAuto()
        const openSize = calcOpenSize();
        const closedSize = calcClosedSize();
        calcViewAllSize();
        calcIsAlreadyOpen(openSize, closedSize);
        unsetHeightAuto()
    },[calcOpenSize, calcClosedSize, calcViewAllSize, calcIsAlreadyOpen]);

    const handleResize = useCallback(() => {
        sizeCalculations();

    },[sizeCalculations]);

    const handleToggle = () => {
        setOpen(!open);
    };

    useEffect(() => {
        sizeCalculations();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    },[sizeCalculations, handleResize]);

    return tags && tags.length ? (
        <Section bg={bg ? bg : "white"} fg="dark" hr={border ? "grey" : null} theme={theme} sectionSibling={sectionSibling}>
            <Container>
                <Wrapper topSpacing={topSpacing}>
                    <Container>
                        <Title>
                            All Topics
                        </Title>
                        <List height={open || hideViewAllButton ? `${openHeight}px` : `${closedHeight}px`} open={open || hideViewAllButton} ref={list}>
                            {tags.map(tag => {
                                return (<ListItem key={tag.tag}>
                                    <ListLink url={`/insights/tag-${tag.slug}`} internal={true}>
                                        {tag.tag}
                                    </ListLink>
                                </ListItem>)
                            })}
                        </List>
                        <ViewAll height={open || hideViewAllButton ? "0" : `${viewAllHeight}px`} ref={viewAll}>
                            <ViewAllButton onClick={handleToggle}>
                                View all
                            </ViewAllButton>
                        </ViewAll>
                    </Container>
                </Wrapper>
            </Container>
        </Section>
    ) : null;
};

export default TagsList;
