import React from "react";
import styled from "@emotion/styled";
import color, {hexToRGB} from "../../styles/colors";
import typography from "../../styles/typography";
import CustomLink from "../Elements/CustomLink";
import FadeUp from "../Effects/FadeUp";
import GatsbyImage from "gatsby-image";

const ArticleImageWrapper = styled.span`
    overflow: hidden;
    display: block;
    padding-top: 68.7%;
    position: relative;

    @media screen and (min-width: 768px) {
        padding-top: ${props =>
            {
                switch(props.useLayout) {
                    case "double":
                        return "0";
                    case "tag":
                        return "0";
                    default:
                        return "68.7%;";
                }
            }
        };
        position: ${props =>
            {
                switch(props.useLayout) {
                    case "double":
                        return "absolute";
                    case "tag":
                        return "absolute";
                    default:
                        return "relative";
                }
            }
        };
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
const ArticleImage = styled(GatsbyImage)`
    transition: transform 0.4s;
    will-change: transform;
    position: absolute !important;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
`;
const Article = styled(FadeUp)`
    margin-bottom: 21px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    color: ${color("dark")};
    text-align: left;
    transition-delay: 0s;
    position: relative;

    @media screen and (min-width: 768px) {
        margin-left: 7.5px;
        margin-right: 7.5px;
        flex-direction: row;
        grid-column-end: ${props =>
            {
                switch(props.useLayout) {
                    case "double":
                    case "tag":
                        return "span 2";
                    default:
                        return "span 1";
                }
            }
        };

        &:first-of-type {
            grid-column-end: ${props =>
                {
                    switch(props.useLayout) {
                        case "single":
                            return "span 1";
                        default:
                            return "span 2";
                    }
                }
            };
        }

        &::before {
            content: "";
            display: block;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 0px;
            padding-top: ${props =>
                {
                    switch(props.useLayout) {
                        case "double":
                            return "34.4%"; // 0.5 width * 68.7%
                        case "tag":
                            return "28.9%"; // for image ratio 68.7% (same as tile image on mobile)
                        default:
                            return "0";
                    }
                }
            };
        }
    }

    @media screen and (min-width: 1024px) {
        transition-delay: ${props => `${props.delay}`};

        &:first-of-type {
            grid-column-end: ${props =>
                {
                    switch(props.useLayout) {
                        case "double":
                        case "tag":
                            return "span 2";
                        default:
                            return "span 1";
                    }
                }
            };
        }
    }

    @media screen and (min-width: 1200px) {
        margin-left: 11.5px;
        margin-right: 11.5px;
    }

    @media screen and (min-width: 1800px) {
        margin-left: 14px;
        margin-right: 14px;
    }

    &:hover {
        ${ArticleImage} {
            transform: scale(1.05);
        }
    }
`;
const ArticleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media screen and (min-width: 768px) {
        flex-direction: ${props =>
            {
                switch(props.useLayout) {
                    case "double":
                        return "row";
                    case "tag":
                        return "row";
                    default:
                        return "column";
                }
            }
        };
        top: 0;
        left: 0;
        min-height: 100%;
        width: 100%;
    }
`;
const ArticleMeta = styled.span`
    display: flex;
    align-items: center;
    background-color: ${color("green")};
    padding: 9px 28px;
    border-bottom: 28px solid ${color("white")};

    @media screen and (min-width: 768px) {
        padding: 8px 24px;
        border-bottom-width: 38px;
    }

    @media screen and (min-width: 1800px) {
        border-bottom-width: 48px;
    }
`;
const PublishDate = styled.p`
    ${typography.tileMeta};
    text-transform: uppercase;
    margin-bottom: 0;
`;
const Author = styled.p`
    ${typography.tileMeta};
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
`;
const ArticleCategories = styled.span`
    display: block;
    padding: 0 18px;
    background-color: ${color("white")};

    @media screen and (min-width: 768px) {
        padding: 0 12px;
    }
`;
const Category = styled(CustomLink)`
    ${typography.meta};
    text-transform: uppercase;
    color: ${color("meta grey")};
    line-height: 1;
    display: inline-block;
    border-right: 1px solid ${color("meta grey")};
    padding: 0 10px;
    text-decoration: none;
    transition: color 0.2s;

    @media screen and (min-width: 768px) {
        padding: 0 12px;
    }

    &:last-child {
        border-right: none;
    }

    &:hover {
        text-decoration: underline;
        color: ${color("dark blue")};
    }
`;
const ArticleTitle = styled.h3`
    ${typography.p1};
    font-weight: bold;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 24px;
    }
`;
const ArticleDescription = styled.span`
    display: block;
    ${typography.standard};
    margin-bottom: 16px;
    flex-grow: 1;

    @media screen and (min-width: 768px) {
        margin-bottom: 19px;
    }
`;
const ArticleCta = styled.span`
    ${typography.link};
    display: inline-block;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
        color: ${color("blue")};
    }
`;
const ArticleLink = styled(CustomLink)`
    display: block;
    text-decoration: none;
    color: ${color("dark")};
`;
const ArticleDetails = styled(CustomLink)`
    display: inline-block;
    text-decoration: none;
    color: ${color("dark")};
    padding: 16px 28px 28px;
    background-color: ${color("white")};
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    hr{
        border-color: ${hexToRGB(color("grey"),0.3)};
    }

    @media screen and (min-width: 768px) {
        padding: 16px 24px 38px;
    }

    @media screen and (min-width: 1800px) {
        padding-top: 24px;
        padding-bottom: 48px;
    }
`;
const ArticleTop = styled(CustomLink)`
    display: block;
    text-decoration: none;
    color: ${color("dark")};
    position: relative;

    @media screen and (min-width: 768px) {
        padding-left: ${props =>
            {
                switch(props.useLayout) {
                    case "double":
                        return "calc(50% + 26px)";
                    case "tag":
                        return "42.07%"; // for image ratio 68.7% (same as tile image on mobile)
                    default:
                        return "0";
                }
            }
        };
    }
`;
const ArticleBottom = styled.span`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const Line = styled.hr`
    text-decoration: none;
    color: inherit;
    width: 15px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top-width: 1px;
    margin: 0 10px;

    @media screen and (min-width: 768px) {
        width: 34px;
        margin: 0 12px;
    }
`;

const Insight = ({date, author, title, description, image, alt, categories, url, linkText, useLayout, fallbackImage}) => {

    const thumbnailImage = image ?? fallbackImage;

    return (
        <Article
            useLayout={useLayout}
        >
            <ArticleWrapper useLayout={useLayout}>
                <ArticleTop
                    url={url}
                    internal={true}
                    useLayout={useLayout}
                >
                    {thumbnailImage && (
                        <ArticleImageWrapper useLayout={useLayout}>
                            <ArticleImage
                                fluid={{...thumbnailImage, sizes: "(min-width: 1800px) 750px,(min-width: 1200px) 50vw, 100vw"}}
                                alt={alt}
                            />
                        </ArticleImageWrapper>
                    )}
                </ArticleTop>
                <ArticleBottom>
                    <ArticleLink url={url} internal={true}>
                        <ArticleMeta>
                            <PublishDate>{date}</PublishDate>
                            <Line />
                            <Author>{author}</Author>
                        </ArticleMeta>
                    </ArticleLink>
                    <ArticleCategories>
                        {categories && categories.length ? categories.map(category => {
                            if(category.category.document) {
                                return (<Category
                                    key={category.category.document.uid}
                                    url={`/insights/category-${category.category.document.uid}`}
                                    internal={true}>
                                    {category.category.document.data.title}
                                </Category>);
                                return null;
                            }
                        }) : null}
                    </ArticleCategories>
                    <ArticleDetails url={url} internal={true}>
                        <ArticleTitle>
                            {title}
                        </ArticleTitle>
                        <ArticleDescription dangerouslySetInnerHTML={{ __html: description }} />
                        <ArticleCta>
                            {linkText}
                        </ArticleCta>
                    </ArticleDetails>
                </ArticleBottom>
            </ArticleWrapper>
        </Article>
    );
};

export default Insight;
