import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import color, { hexToRGB } from "../../styles/colors";
import TickSvg from "../../images/tick.svg";
import { graphql, Link, useStaticQuery } from "gatsby";
import SpinnerSVG from "../SVGs/SpinnerSvg";
import fetchJsonp from "../../util/fetchJsonp";
import { track } from "../../util/analytics";

const inputHeightMobile = "48px";
const inputHeightTablet = "56px";
const inputHeight1200 = inputHeightTablet;
const inputHeight1800 = inputHeightTablet;

const inputMarginBottomMobile = "24px";
const inputMarginBottomTablet = inputMarginBottomMobile;
const inputMarginBottom1200 = "40px";
const inputMarginBottom1800 = "40px";

const Container = styled.div``;
const Title = styled.h2`
    font-family: Neue Haas Unica, sans-serif;
    line-height: 1.2;
    text-transform: uppercase;
    font-size: 26px;
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
        font-size: 35px;
        margin-bottom: 40px;
    }
    @media screen and (min-width: 1024px) {
        font-size: 40px;
    }
    strong {
        font-weight: bold;
    }
`;

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 1080px) {
        width: auto;
    }
`;

const InputContainer = styled.div`
    position: relative;
    flex: 1;
    width: 100%;
    margin-bottom: 0;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: stretch;
        align-items: flex-end;
    }
    @media screen and (min-width: 1200px) {
        align-items: center;
    }
    @media screen and (min-width: 1800px) {
        align-items: flex-end;
    }
`;
const FieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: ${inputMarginBottomMobile};

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-bottom: ${inputMarginBottomTablet};

        &.field-container {
            &--email {
                width: 100%;
            }
        }
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: ${inputMarginBottom1200};
        width: 100%;

        &.field-container {
            margin-bottom: ${inputMarginBottom1200};
            &--email {
                flex-grow: 1;
            }
        }
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: ${inputMarginBottom1800};

        &.field-container {
        }
    }
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    width: 100%;
    text-align: left;
    &:first-of-type {
        margin-bottom: 36px;
        margin-top: 26px;
    }

    @media screen and (min-width: 1200px) {
        width: auto;

        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
`;
const Input = styled.input`
    ${typography.label};
    width: 100%;
    letter-spacing: 0.04em;
    text-transform: none;
    appearance: none;
    background: white;

    padding: 0 15px;
    color: ${color("dark")};
    box-sizing: border-box;
    background: none;
    border: 1px solid ${color("dark")};
    height: ${inputHeightMobile};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0;

    &:focus {
        outline: none;
    }

    @media screen and (min-width: 768px) {
        padding: 0 23px;
        height: 100%;
        width: 100%;
        height: ${inputHeightTablet};
    }

    @media screen and (min-width: 1200px) {
        height: ${inputHeight1200};
    }

    @media screen and (min-width: 1800px) {
        height: ${inputHeight1800};
    }
`;

const CheckboxInput = styled.input`
    display: none;

    &:checked + label {
        &::before {
            background: ${color("white")};
        }
        &::after {
            opacity: 1;
        }
    }
`;
const Label = styled.label`
    ${typography.label};
    font-weight: 600;
    position: static;
    opacity: 1;
    line-height: 1.2;
    margin-bottom: 10px;
    display: inline-block;
    height: 17px;
`;
const CheckboxLabel = styled.label`
    ${typography.label};
    text-transform: none;
    color: ${color("dark")};
    transition: opacity 0.2s;
    cursor: pointer;
    box-sizing: border-box;
    background: none;
    border-left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    > div {
        padding-left: 16px;
    }

    &::before {
        content: "";
        background: none;
        border: 1px solid ${color("dark")};
        padding: 14px;
        display: inline-block;
        transition: background-color 0.2s ease;
    }

    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        width: 28px;
        height: 28px;
        top: 1px;
        left: 1px;
        opacity: 0;
        transition: opacity 0.2s ease;
        background-image: url(${TickSvg});
        background-position: center center;
        background-repeat: no-repeat;
    }

    &:hover,
    &:focus {
        &::before {
            background: ${hexToRGB(color("white"), 0.2)};
        }
    }

    &:focus {
        outline: none;
    }

    @media screen and (min-width: 768px) {
        padding-right: 20px;
        margin-right: 28px;

        &:first-of-type {
            margin-bottom: 16px;
        }
    }

    @media screen and (min-width: 1200px) {
        width: auto;
        margin-right: 42px;

        &:first-of-type {
            margin-bottom: 0;
        }
    }
`;

const CheckboxLabelTitle = styled.div`
    margin-bottom: 5px;
    strong {
        font-weight: bold;
    }
`;
const CheckboxLabelMeta = styled.div`
    ${typography.meta};
`;
const Submit = styled.button`
    ${typography.label};
    border: none;
    padding: 0 47px;
    display: inline-block;
    font-weight: bold;
    background: none;
    height: ${inputHeightMobile};
    flex: 0;
    background: ${color("dark")};
    color: ${color("white")};
    cursor: hand;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin: 0;
    width: 100%;

    &:hover {
        background: ${color("base blue")};
    }

    &:focus {
        outline: none;
        background: ${color("base blue")};
    }

    span {
        position: relative;
        top: -1px;
    }

    @media screen and (min-width: 768px) {
        height: ${inputHeightTablet};
        flex-grow: 1;
    }

    @media screen and (min-width: 1200px) {
        height: ${inputHeight1200};
        margin-right: 0;
        padding: 0 45px;
        flex-grow: 0;
    }

    @media screen and (min-width: 1800px) {
        height: ${inputHeight1800};
        width: auto;
        padding: 0 65px;
    }
`;
const Terms = styled.div`
    ${typography.meta};
    color: ${hexToRGB(color("dark"), 0.6)};
    margin-top: ${inputMarginBottomMobile};
    width: 100%;

    @media screen and (min-width: 768px) {
        margin-top: ${inputMarginBottomTablet};
    }

    @media screen and (min-width: 1200px) {
        margin-top: ${inputMarginBottom1200};
    }

    @media screen and (min-width: 1800px) {
        margin-top: ${inputMarginBottom1800};
    }
    a {
        color: ${hexToRGB(color("dark"), 0.6)};
        transition: color 0.2s ease;
        &:visited {
            color: ${hexToRGB(color("dark"), 0.6)};
        }

        &:hover {
            color: ${color("dark blue")};
        }
    }
`;

const Message = styled.div`
    height: ${props => props.inputContainerElHeight};
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    position: relative;
`;
const MessageText = styled.div`
    ${typography.label};
    text-transform: none;
    font-family: Neue Haas Unica, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 38px;
`;
const ErrorText = styled.div`
    ${typography.label};
    text-align: center;
    font-family: Neue Haas Unica, sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-transform: none;
    margin-bottom: 16px;
    width: 100%;
`;
const Loading = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
const Spinner = styled.div`
    border-radius: 50%;
    animation: 1.2s linear 0s infinite running spin;

    svg {
        fill: ${color("dark")};
        stroke: ${color("dark")};
        width: 30px;
        height: 30px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media screen and (min-width: 1200px) {
        svg {
            width: 60px;
            height: 60px;
        }
    }
`;

const Close = styled.button`
    ${typography.label};
    border: none;
    padding: 0 47px;
    //display: inline-block;
    font-weight: bold;
    background: none;
    height: ${inputHeightMobile};
    //flex: 0;
    background: ${color("white")};
    color: ${color("dark")};
    border: 2px solid ${color("dark")};
    cursor: pointer;
    transition: color 0.2s ease, border-color 0.2s ease;
    margin: 0;
    width: 100%;

    &:hover {
        color: ${color("dark blue")};
        border-color: ${color("dark blue")};
    }

    &:focus {
        outline: none;
        color: ${color("dark blue")};
    }

    span {
        position: relative;
        top: -1px;
    }

    @media screen and (min-width: 768px) {
        height: ${inputHeightTablet};
        //flex-grow: 1;
        width: auto;
    }

    @media screen and (min-width: 1200px) {
        height: ${inputHeight1200};
        margin-right: 0;
        padding: 0 45px;
        //flex-grow: 0;
    }

    @media screen and (min-width: 1800px) {
        height: ${inputHeight1800};
        width: auto;
        padding: 0 65px;
    }
`;

const Form = ({ closeModal = () => {}, modalOpen }) => {
    const { prismicOptions } = useStaticQuery(
        graphql`
            {
                prismicOptions {
                    data {
                        form_name {
                            html
                        }
                        legal_text {
                            html
                        }
                    }
                }
            }
        `
    );
    const [focused, setFocused] = useState(false);
    const [hasContent, setHasContent] = useState(false);
    const [success, setSuccess] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [languageNotSelected, setLanguageNotSelected] = useState(false);

    const inputContainerEl = useRef(null);
    const inputContainerElHeight = useRef(0);

    useEffect(() => {
        inputContainerElHeight.current =
            inputContainerEl.current.clientHeight + "px";
        window.addEventListener("resize", updateFormHeight);

        return () => {
            window.removeEventListener("resize", updateFormHeight);
        };
    }, []);

    useEffect(() => {
        updateFormHeight();
    }, [focused]);

    const updateFormHeight = () => {
        if (inputContainerEl.current !== null) {
            inputContainerElHeight.current =
                inputContainerEl.current.clientHeight + "px";
        }
    };

    const submitForm = e => {
        if (!loading) {
            e.preventDefault();
            setInvalid(false);
            setError(false);
            setLanguageNotSelected(false);
            setLoading(true);

            // check that at least one language is selected
            const numLanguagesSelected = e.currentTarget.querySelectorAll(
                "input[type=checkbox]:checked"
            ).length;
            if (numLanguagesSelected === 0) {
                setLanguageNotSelected(true);
                setLoading(false);
            } else {
                // submit
                let data = new FormData(e.currentTarget);
                let action = e.currentTarget.getAttribute("action");
                let params = new URLSearchParams([...data]);
                fetchJsonp(`${action}?${params.toString()}`, {
                    jsonpCallback: "c",
                })
                    .then(response => response.json())
                    .then(
                        response => {
                            const { msg, result } = response;
                            if (
                                result === "success" ||
                                msg.includes("is already subscribed")
                            ) {
                                setSuccess(true);
                            } else if (
                                msg.includes(
                                    "Please enter a different email address"
                                )
                            ) {
                                setInvalid(true);
                            } else {
                                setError(true);
                            }
                            setLoading(false);
                            track({ event: "SubscribeFormSubmitted" });
                        },
                        error => {
                            setError(true);
                            setLoading(false);
                        }
                    );
            }
        }
    };
    return (
        <Container>
            <Title>
                <p>
                    SIGN UP FOR THE <strong>LATEST NEWS</strong> AND{" "}
                    <strong>INSIGHTS</strong>
                </p>
            </Title>
            <FormContainer
                action="https://gladeye.us4.list-manage.com/subscribe/post-json"
                method="POST"
                onSubmit={submitForm}
            >
                <input
                    type="hidden"
                    name="u"
                    value="f7204e697ebd16299ab45914d"
                />
                <input type="hidden" name="id" value="2d90c08c90" />
                <input type="hidden" name="TYPE" value="Company" />
                {success ? (
                    <Message
                        inputContainerElHeight={inputContainerElHeight.current}
                    >
                        <MessageText>
                            Thanks for signing up. Please check your email to
                            confirm your subscription.
                        </MessageText>
                        <Close onClick={closeModal}>CLOSE</Close>
                    </Message>
                ) : loading ? (
                    <Message
                        inputContainerElHeight={inputContainerElHeight.current}
                    >
                        <Loading>
                            <Spinner>
                                <SpinnerSVG />
                            </Spinner>
                        </Loading>
                    </Message>
                ) : (
                    <InputContainer ref={inputContainerEl}>
                        {invalid ? (
                            <ErrorText>
                                Please enter a valid email address.
                            </ErrorText>
                        ) : error ? (
                            <ErrorText>
                                There was an issue submitting your details.
                                Please try again.
                            </ErrorText>
                        ) : languageNotSelected ? (
                            <ErrorText>
                                Please select at least one language.
                            </ErrorText>
                        ) : null}
                        <FieldContainer className="field-container--email">
                            <Label
                                htmlFor={"modal_email"}
                                minimise={focused || hasContent}
                                className="field-label"
                            >
                                Email Address
                            </Label>
                            <Input
                                id={"modal_email"}
                                type={"email"}
                                name={"MERGE0"}
                                required
                                onChange={e => {
                                    if (e.currentTarget.value) {
                                        setHasContent(true);
                                    } else {
                                        setHasContent(false);
                                    }
                                }}
                                onFocus={() => {
                                    setFocused(true);
                                }}
                                onBlur={() => {
                                    setFocused(false);
                                }}
                            />
                        </FieldContainer>

                        <FieldContainer>
                            <Label>Select Newsletters</Label>

                            <OptionsContainer>
                                <CheckboxInput
                                    id={"modal_group_2"}
                                    type={"checkbox"}
                                    name={"group[71354][2]"}
                                    value={"1"}
                                />
                                <CheckboxLabel htmlFor={"modal_group_2"}>
                                    <div>
                                        <CheckboxLabelTitle>
                                            <strong>T+10 - </strong>How tech
                                            transforms our lives
                                        </CheckboxLabelTitle>
                                        <CheckboxLabelMeta>
                                            English Newsletter
                                        </CheckboxLabelMeta>
                                    </div>
                                </CheckboxLabel>
                            </OptionsContainer>
                            <OptionsContainer>
                                <CheckboxInput
                                    id={"modal_group_1"}
                                    type={"checkbox"}
                                    name={"group[71354][1]"}
                                    value={"1"}
                                />
                                <CheckboxLabel htmlFor={"modal_group_1"}>
                                    <div>
                                        <CheckboxLabelTitle>
                                            <strong>The Glitch - </strong>
                                            Environnements virtuels et
                                            technologies émergentes
                                        </CheckboxLabelTitle>
                                        <CheckboxLabelMeta>
                                            Newsletter en français
                                        </CheckboxLabelMeta>
                                    </div>
                                </CheckboxLabel>
                            </OptionsContainer>
                        </FieldContainer>

                        <Submit>
                            <span>Submit</span>
                        </Submit>

                        <Terms
                            dangerouslySetInnerHTML={{
                                __html: prismicOptions.data.legal_text.html,
                            }}
                        />
                    </InputContainer>
                )}
            </FormContainer>
        </Container>
    );
};

export default Form;
