import React, { useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import Footer from "../components/Context/Footer";
import HeroInsightsArticle from "../components/General/HeroInsightsArticle";
import FeatureImageInsightsArticle from "../components/General/FeatureImageInsightsArticle";
import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";
import GatsbyImage from "gatsby-image";
import BodyInsight from "../components/General/BodyInsight";
import InsightsBlock from "../components/Insights/InsightsBlock";
import TagsList from "../components/Insights/TagsList";
import CTA from "../components/General/CTA";
import CategoriesBlockList from "../components/Insights/CategoriesBlockList";
import Helmet from "react-helmet";

const Insight = ({ data: staticData, pageContext, previewImage }) => {
    const data = usePreviewData(staticData);
    const {
        url: pageUrl,
        tags,
        data: {
            title,
            categories,
            author,
            date,
            featured_image,
            caption,
            credits,
            body,
            meta_description,
            share_image,
        } = {},
    } = data.prismicInsight;
    const { data: { heading_promo, teaser, button_cta, link_promo } = {} } =
        data.prismicInsightsLanding ?? {};

    const hasNewsletter = body.find(slice => {
        return slice.__typename === "PrismicInsightBodyNewsletterCta";
    });
    useEffect(() => {
        let eventContent = {
            event: "contentGrouping",
            article: {
                topic: tags,
                category: categories
                    ?.map(category => category.category.document?.data.title)
                    ?.filter(c => !!c),
            },
        };
        if (window._mtm) {
            window._mtm.push(eventContent);
        }
    }, [categories, tags]);
    useEffect(() => {
        if (window._mtm) {
            window._mtm.push({
                hasNewsletter: hasNewsletter ? "true" : "false",
            });
            window._mtm.push({ event: "optimize.activate" });
        }
    }, [hasNewsletter]);

    const theme = "dark";

    const imagePlaceholder = data.file?.childImageSharp ?? previewImage;

    const authorName = author.document?.data.name.text;

    const socialLinks = [];
    const emailUrl = author.document?.data.email?.url;
    const twitterUrl = author.document?.data.twitter?.url;
    const linkedInUrl = author.document?.data.linkedin?.url;
    if (emailUrl)
        socialLinks.push({
            name: "Email",
            url: emailUrl,
            key: `email`,
        });

    if (twitterUrl)
        socialLinks.push({
            name: "Twitter",
            url: twitterUrl,
            key: `twitter`,
        });

    if (linkedInUrl)
        socialLinks.push({
            name: "LinkedIn",
            url: linkedInUrl,
            key: `linkedin`,
        });

    const tagsWithSlug = tags?.map(tag => {
        return {
            title: tag,
            slug: slugify(tag),
        };
    });

    return (
        <Layout>
            <SEO
                title={title.text}
                description={meta_description}
                image={share_image.url}
            />
            <Header theme={theme} />
            <HeroInsightsArticle
                theme={theme}
                title={title.text}
                date={date}
                author={authorName}
                pageUrl={pageUrl}
            />
            <FeatureImageInsightsArticle
                theme={theme}
                image={
                    featured_image && featured_image.fluid ? (
                        <GatsbyImage
                            fluid={{
                                ...featured_image.fluid,
                                sizes: "(min-width: 1800px) 1334px, 100vw",
                            }}
                            alt={featured_image.alt}
                            loading="eager"
                        />
                    ) : null
                }
                creditsHtml={caption.html}
            />
            <BodyInsight
                theme={theme}
                type="article"
                title={title.text}
                pageUrl={pageUrl}
                slices={body}
                categories={categories}
                tags={tagsWithSlug}
                author={{
                    name: authorName,
                    description: author.document?.data.description.html,
                    alt: author.document?.data.image?.alt,
                    fluid: author.document?.data.image?.fluid,
                    role: author.document?.data.title,
                    social: socialLinks,
                }}
                credits={credits.html}
            />
            {data.allPrismicInsight ? (
                <InsightsBlock
                    theme={theme}
                    title="Related Articles"
                    articles={[
                        ...data.relatedInsights.nodes,
                        ...data.allPrismicInsight.nodes,
                    ]
                        .slice(0, 3)
                        .map(article => {
                            return {
                                key: article.id,
                                url: article.url,
                                description: article.data.summary.html,
                                date: article.data.date,
                                author:
                                    article.data.author.document?.data.name
                                        .text,
                                title: article.data.title.text,
                                image:
                                    article.data.thumbnail?.fluid ??
                                    imagePlaceholder?.fluid,
                                alt: article.data.featured_image?.alt,
                                categories: article.data.categories,
                                type: null,
                                buttonText: "Read more",
                            };
                        })}
                    bg="insights blue"
                />
            ) : null}
            {pageContext?.categories ? (
                <CategoriesBlockList
                    categories={pageContext.categories}
                    bg="insights blue"
                    theme={theme}
                    sectionSibling={true}
                    topSpacing={true}
                />
            ) : null}
            {pageContext?.tags ? (
                <TagsList
                    tags={pageContext.tags}
                    bg="insights blue"
                    theme={theme}
                    sectionSibling={true}
                />
            ) : null}
            {data.prismicInsightsLanding ? (
                <CTA
                    theme={theme}
                    title={heading_promo.text}
                    content={teaser.html}
                    buttonText={button_cta}
                    buttonUrl={link_promo.url}
                    buttonInternal={true}
                />
            ) : null}
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query Insight($slug: String!, $insightTags: [String]) {
        prismicInsight(uid: { eq: $slug }) {
            uid
            url
            tags
            data {
                categories {
                    category {
                        document {
                            ... on PrismicCategory {
                                data {
                                    title
                                }
                                uid
                            }
                        }
                    }
                }
                author {
                    id
                    document {
                        ... on PrismicAuthor {
                            id
                            data {
                                name {
                                    text
                                }
                                title
                                description {
                                    html
                                }
                                image {
                                    alt
                                    fluid(maxWidth: 522) {
                                        ...GatsbyPrismicImageFluid
                                    }
                                }
                                email {
                                    target
                                    url
                                    link_type
                                }
                                linkedin {
                                    url
                                    target
                                    link_type
                                }
                                twitter {
                                    target
                                    url
                                    link_type
                                }
                            }
                        }
                    }
                }
                body {
                    ... on PrismicInsightBodyLead {
                        id
                        primary {
                            content {
                                html
                            }
                        }
                    }
                    ... on PrismicInsightBodyRichText {
                        id
                        primary {
                            content {
                                html
                                raw
                            }
                        }
                    }
                    ... on PrismicInsightBodyImage {
                        id
                        primary {
                            caption1 {
                                html
                            }
                            image {
                                alt
                                dimensions {
                                    width
                                    height
                                }
                                fluid(maxWidth: 4000) {
                                    ...GatsbyPrismicImageFluid
                                }
                            }
                        }
                    }
                    ... on PrismicInsightBodyVideo {
                        id
                        primary {
                            video {
                                width
                                height
                                html
                            }
                            caption1 {
                                html
                            }
                        }
                    }
                    ... on PrismicInsightBodyQuote {
                        id
                        primary {
                            quote {
                                html
                            }
                        }
                    }
                    ... on PrismicInsightBodyIframeEmbed {
                        id
                        primary {
                            embed
                            width
                            height
                        }
                    }
                    ... on PrismicInsightBodyNewsletterCta {
                        id
                        primary {
                            title1
                            location_key
                        }
                    }
                }
                caption {
                    html
                }
                credits {
                    html
                }
                date(formatString: "MMM D YYYY")
                featured_image {
                    alt
                    fluid(maxWidth: 5000) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                meta_description
                share_image {
                    url
                }
                summary {
                    html
                }
                title {
                    html
                    text
                }
                meta_description
                share_image {
                    url
                }
            }
        }
        allPrismicInsight(
            filter: { uid: { ne: $slug }, tags: { nin: $insightTags } }
            sort: { fields: data___date, order: DESC }
            limit: 3
        ) {
            nodes {
                id
                uid
                url
                data {
                    title {
                        text
                    }
                    summary {
                        html
                    }
                    date(formatString: "MMM D YYYY")
                    thumbnail {
                        alt
                        fluid(maxWidth: 2250) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                    author {
                        document {
                            ... on PrismicAuthor {
                                data {
                                    name {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    categories {
                        category {
                            document {
                                ... on PrismicCategory {
                                    data {
                                        title
                                    }
                                    uid
                                }
                            }
                        }
                    }
                }
            }
        }
        relatedInsights: allPrismicInsight(
            filter: { uid: { ne: $slug }, tags: { in: $insightTags } }
            sort: { fields: data___date, order: DESC }
            limit: 3
        ) {
            nodes {
                id
                uid
                url
                data {
                    title {
                        text
                    }
                    summary {
                        html
                    }
                    date(formatString: "MMM D YYYY")
                    thumbnail {
                        alt
                        fluid(maxWidth: 2250) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                    author {
                        document {
                            ... on PrismicAuthor {
                                data {
                                    name {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    categories {
                        category {
                            document {
                                ... on PrismicCategory {
                                    data {
                                        title
                                    }
                                    uid
                                }
                            }
                        }
                    }
                }
            }
        }
        prismicInsightsLanding {
            data {
                heading_promo {
                    text
                }
                teaser {
                    html
                }
                button_cta
                link_promo {
                    url
                    target
                    link_type
                }
            }
        }
        file(name: { eq: "placeholder-insights-image" }) {
            childImageSharp {
                fluid(maxWidth: 5000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;

function slugify(text) {
    return text
        .toString()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "")
        .replace(/--+/g, "-");
}

export default Insight;
