import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import GatsbyImage from "gatsby-image";
import HoverDropdown from "../Elements/HoverDropdown";

const AuthorWrapper = styled.div`
    border-top: 1px solid ${color("grey")};
    margin-top: 64px;
    padding-top: 64px;
    text-align: center;

    @media screen and (min-width: 768px) {
        margin-top: 72px;
        padding-top: 72px;
        text-align: left;
    }

    @media screen and (min-width: 1200px) {
        margin-top: 105px;
        padding-top: 105px;
    }

    @media screen and (min-width: 1800px) {
        margin-top: 120px;
        padding-top: 120px;
    }

    @media screen and (min-width: 2200px) {
        margin-top: 145px;
        padding-top: 145px;
    }
`;

const Inner = styled.div`
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
    }
`;

const ImageWrapper = styled.div`
    width: 140px;
    height: 140px;
    border-radius: 50%;
    margin: 0 auto 10px;
    overflow: hidden;

    @media screen and (min-width: 768px) {
        max-width: 174px;
        width: 174px;
        height: 174px;
        flex-shrink: 0;
        margin: 0;
        margin-right: 58px;
    }
`;

const Image = styled(GatsbyImage)`
    height: 100%;
`;

const Details = styled.div`
    align-self: center;
`;

const Name = styled.h3`
    ${typography.h3};
    font-weight: 700;
    margin-bottom: 4px;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
        ${typography.p1};
        font-weight: 700;
        text-transform: none;
        margin-bottom: 4px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 4px;
    }
`;

const Role = styled.p`
    ${typography.label};
    margin-bottom: 10px;

    @media screen and (min-width: 768px) {
        margin-bottom: 8px;
    }
`;

const DescriptionWrapper = styled.div`
    @media screen and (min-width: 1200px) {
        margin-bottom: 24px;
    }
`;

const Description = styled.span`
    ${typography.body2};

    @media screen and (min-width: 1800px) {
        font-size: 18px;
    }
`;

const ConnectLinks = styled.div`
    display: none;

    @media screen and (min-width: 1200px) {
        display: block;
    }
`;

const AuthorBio = ({author}) => {
    const {name, role, description, fluid, alt, social} = author;

    return (
        <AuthorWrapper>
            <Inner>
                {fluid ? <ImageWrapper>
                    <Image fluid={{...fluid, sizes: "(min-width: 768px) 174px, 140px"}} alt={alt} />
                </ImageWrapper> : null}
                <Details>
                    <Name>{name}</Name>
                    <Role>{role}</Role>
                    <DescriptionWrapper>
                        <Description dangerouslySetInnerHTML={{__html: description}} />
                    </DescriptionWrapper>
                    {social && social.length ? (
                        <ConnectLinks>
                            <HoverDropdown
                                title="Connect"
                                links={social}
                                reverse={true}
                                hideTitleMobile={true}
                                breakTablet={true}
                                width="100%"
                            />
                        </ConnectLinks>
                    ) : null}
                </Details>
            </Inner>
        </AuthorWrapper>
    );
};

export default AuthorBio;
