import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import Section from "../Context/Section";
import Container from "../Context/Container";
import color, { hexToRGB } from "../../styles/colors";

const ImageWrapper = styled.div`
    margin: 0 -20px;

    @media screen and (min-width: 600px) {
        margin: 0 -48px;
    }

    @media screen and (min-width: 768px) {
        margin: 0;
        width: 100%;
    }

    img{
        width: 100%;
    }
`;

const Caption = styled.div`
    ${typography.meta};

    p{
        margin-top: 8px;
        color: ${hexToRGB(color("dark"), 0.6)};
    }

    a{
        word-wrap: break-word;
        color: ${hexToRGB(color("dark"), 0.6)};
        transition: color 0.2s ease;

        &:hover{
            color: ${hexToRGB(color("dark"), 1)};
        }
    }
`;

const FeatureImageInsightsArticle = ({image, creditsHtml, theme}) => {

    return (
        <Section paddingS="0" paddingM="0" paddingL="0" paddingXL="0" paddingXXL="0" bg="white" fg="dark" theme={theme}>
            <Container>
                <ImageWrapper>
                    {image}
                </ImageWrapper>
                <div>
                    {creditsHtml && <Caption dangerouslySetInnerHTML={{__html: creditsHtml}} />}
                </div>
            </Container>
        </Section>
    );
};

export default FeatureImageInsightsArticle;
