import React from "react";
import styled from "@emotion/styled";
import color from "../../styles/colors";
import { Link } from 'gatsby';
import Insight from "./Insight";
import Container from "../Context/Container";
import typography from "../../styles/typography";
import Section from "../Context/Section";

const Intro = styled.div`
    margin-bottom: ${props => props.large ? "40px" : "0"};

    p{
        &:last-of-type{
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: ${props => props.large ? "56px" : "0"};
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: ${props => props.large ? "57px" : "0"};
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: ${props => props.large ? "49px" : "0"};
    }
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 17px;

    a{
        ${typography.link};
        color: ${color("dark")};
        display: flex;
        flex-direction: row;
        transition: color 0.2s ease;
        white-space: nowrap;

        &:hover{
            color: ${color("dark blue")};
        }
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 26px;
    }

    @media screen and (min-width: 1200px) {
        align-items: flex-end;
        margin-bottom: 34px;

        a{
            margin-bottom: 0.6em;
        }
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 28px;
    }
`;
const Title = styled.h2`
    ${props => props.large ? typography.h2 : typography.h3};
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 0;
    font-weight: 600;

    @media screen and (min-width: 768px) {
        line-height: 1.1;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1200px) {
        line-height: 1.05;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 0;
    }
`;

const Standard = styled.div`
    ${typography.standard};
    ${typography.RTESpacing};
    p{
        @media screen and (min-width: 1200px) {
            max-width: 986px;
        }

        @media screen and (min-width: 1800px) {
            max-width: 1100px;
        }
    }
`;
const FeaturedWrapper = styled.div`
   @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr;
        margin-left: -7.5px;
        margin-right: -7.5px;
    }

    @media screen and (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: 1200px) {
        margin-left: -11.5px;
        margin-right: -11.5px;
    }

    @media screen and (min-width: 1800px) {
        margin-left: -14px;
        margin-right: -14px;
    }
`;

const InsightsBlock = ({title, intro, articles, viewAll, border, bg, theme, sectionSibling}) => {

    const ArticleList = articles.map((single, index) => {
        return (
            <Insight
                title={single.title}
                description={single.description}
                date={single.date}
                author={single.author}
                image={single.image}
                alt={single.alt}
                categories={single.categories}
                linkText={single.buttonText}
                url={single.url}
                internal={single.type ? false : true}
                key={single.key}
                index={index}
            />
        )
    });

    return (
        <Section bg={bg ? bg : "white"} fg="dark" hr={border ? "grey" : null} theme={theme} sectionSibling={sectionSibling}>
            <Container>
                {title || viewAll ? (
                    <Intro large={viewAll}>
                        <Row>
                            <Title large={viewAll}>
                                {title}
                            </Title>
                            {viewAll ? <Link to="/insights">See the latest</Link> : null}
                        </Row>
                        <Standard dangerouslySetInnerHTML={{ __html: intro }} />
                    </Intro>
                ) : null}
                <FeaturedWrapper>
                    {ArticleList}
                </FeaturedWrapper>
            </Container>
        </Section>
    );
};

export default InsightsBlock;
