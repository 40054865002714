import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import Modal from "./Modal";
import color from "../../styles/colors";
import { Transition, TransitionGroup } from "react-transition-group";
import Optimize from "../Context/Optimize";
const Container = styled.div`
    display: ${({ show }) => (show ? "block" : "none")};
    background: linear-gradient(
        71.97deg,
        #a9c4e7 -42.4%,
        #a9c4e7 13.94%,
        #ffd0c0 102.21%
    );

    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }
`;
const Title = styled.h3`
    text-transform: uppercase;
    font-size: 20px;
    margin-bottom: 40px;
    padding: 12px 12px 0 12px;
    @media screen and (min-width: 768px) {
        font-size: 42px;
        margin-bottom: 70px;
        padding: 30px 30px 0 30px;
    }
    line-height: 1.2;
    font-weight: 700;
`;
const OpenModal = styled.button`
    background: none;
    border: 0;
    border-top: 2px solid ${color("white")};
    width: 100%;
    text-align: left;
    padding: 12px;
    text-transform: uppercase;
    font-size: 18px;

    font-family: Faktum, sans-serif;
    font-weight: 600;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: color 0.2s ease;
    svg {
        width: 12px;
        height: 12px;
    }
    path {
        transition: stroke 0.2s ease;
    }
    cursor: pointer;
    @media screen and (min-width: 768px) {
        padding: 30px;
        svg {
            width: 25px;
            height: 25px;
        }
    }
    &:hover,
    &:focus {
        color: ${color("blue")};
        path {
            stroke: ${color("blue")};
        }

        outline: 0;
    }
`;

const CTA = ({ title, location }) => {
    let show = true;

    const [modalOpen, setModalOpen] = useState(false);
    return (
        <Container show={show}>
            <Title>{title}</Title>
            <OpenModal onClick={() => setModalOpen(true)}>
                Sign Up{" "}
                <svg
                    width="25"
                    height="25"
                    viewBox="0 0 27 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1 26L26 1M26 1H3.61628M26 1V23.3837"
                        stroke="#001428"
                        strokeWidth="2"
                    />
                </svg>
            </OpenModal>
            <TransitionGroup>
                {modalOpen ? (
                    <Transition timeout={{ enter: 300, exit: 300 }}>
                        {status => (
                            <Modal
                                status={status}
                                modalOpen={modalOpen}
                                closeModal={() => setModalOpen(false)}
                            />
                        )}
                    </Transition>
                ) : null}
            </TransitionGroup>
        </Container>
    );
};

export default CTA;
