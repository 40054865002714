import React, { Fragment } from "react";
import styled from "@emotion/styled";
import color, { hexToRGB } from "../../styles/colors";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";
import HoverDropdown from "../Elements/HoverDropdown";
import { graphql, useStaticQuery } from "gatsby";
import IframeHtml from "./IframeHtml";
import ContentImage from "./ContentImage";
import AuthorBio from "../Insights/AuthorBio";
import CustomLink from "../Elements/CustomLink";
import CTA from "../Newsletter/CTA";

const Standard = styled.div`
    ${typography.standard};
    color: ${color("dark")};
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }

    h1 {
        ${typography.h2};
        margin-top: 64px;

        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 21px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 16px;
        }
    }

    h2 {
        ${typography.h3};
        margin-bottom: 24px;
        font-weight: bold;
        margin-top: 64px;

        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 21px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 16px;
        }
    }

    h3 {
        ${typography.h4};
        margin-bottom: 26px;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 64px;
        font-size: 18px;

        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 21px;
            font-size: 20px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 16px;
        }
    }

    h4,
    h5,
    h6 {
        ${typography.label};
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: 600;
        margin-top: 64px;

        @media screen and (min-width: 768px) {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 21px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 16px;
        }
    }

    .lead,
    .quote {
        ${typography.p1};
        display: block;
        margin-top: 48px !important;
        margin-bottom: 48px;

        @media screen and (min-width: 768px) {
            width: 81%;
            margin-top: 56px !important;
            margin-bottom: 56px;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 64px !important;
            margin-bottom: 64px;
        }

        @media screen and (min-width: 1800px) {
            margin-top: 72px !important;
            margin-bottom: 72px;
        }
    }

    .lead {
        font-weight: 600;
    }

    p:first-child {
        .lead,
        .quote {
            margin-top: 0 !important;
        }
    }

    p:last-child {
        .lead,
        .quote {
            margin-bottom: 0;
        }
    }

    figure,
    .embed-container {
        margin-top: 25px;
        margin-bottom: 25px;
        text-align: center;

        .inner {
            display: inline-block;
            text-align: left;
        }

        figcaption {
            ${typography.meta};
            margin-top: 8px;
            text-align: left;
            color: ${hexToRGB(color("dark"), 0.6)};
        }

        @media screen and (min-width: 768px) {
            margin-top: 48px;
            margin-bottom: 48px;
        }

        @media screen and (min-width: 1200px) {
            width: auto;
            text-align: center;
        }

        @media screen and (min-width: 1800px) {
            margin-top: 72px;
            margin-bottom: 72px;

            figcaption {
                margin-top: 16px;
            }
        }
    }

    h1 + h2,
    h1 + h3,
    h1 + h4,
    h1 + h5,
    h1 + h6,
    h2 + h3,
    h2 + h4,
    h2 + h5,
    h2 + h6,
    h3 + h4,
    h3 + h5,
    h3 + h6,
    h4 + h5,
    h4 + h6,
    h5 + h6 {
        margin-top: 0;
    }

    a {
        word-wrap: break-word;
    }

    &:first-child {
        p:first-child {
            .lead {
                margin-bottom: 16px;

                @media screen and (min-width: 768px) {
                    margin-bottom: 32px;
                }

                @media screen and (min-width: 1200px) {
                    margin-bottom: 37px;
                }

                @media screen and (min-width: 1800px) {
                    margin-bottom: 41px;
                }
            }
        }
    }
`;

const Lead = styled.div`
    ${typography.standard};
    color: ${color("dark")};
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }

    &:first-child {
        margin-bottom: 16px;

        @media screen and (min-width: 768px) {
            margin-bottom: 32px;
        }

        @media screen and (min-width: 1200px) {
            margin-bottom: 37px;
        }

        @media screen and (min-width: 1800px) {
            margin-bottom: 41px;
        }
    }

    p {
        ${typography.p1};
        display: block;
        font-weight: 600;
        margin-top: 24px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 24px;
        }

        @media screen and (min-width: 768px) {
            width: 81%;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 32px;
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 32px;
            }
        }
    }

    a {
        word-wrap: break-word;
    }
`;

const Quote = styled.div`
    ${typography.standard};
    color: ${color("dark")};
    margin-bottom: 48px;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }

    p {
        ${typography.p1};
        display: block;
        margin-top: 32px;
        margin-bottom: 32px;
        margin-left: auto;
        margin-right: auto;

        &:last-child {
            margin-bottom: 32px;
        }

        @media screen and (min-width: 768px) {
            width: 81%;
        }

        @media screen and (min-width: 1200px) {
            margin-top: 42px;
            margin-bottom: 42px;

            &:last-child {
                margin-bottom: 42px;
            }
        }

        @media screen and (min-width: 1800px) {
            margin-top: 56px;
            margin-bottom: 56px;

            &:last-child {
                margin-bottom: 56px;
            }
        }
    }

    a {
        word-wrap: break-word;
    }
`;

const SocialLinks = styled.div`
    width: auto;
`;

const RelativeWrapper = styled.div`
    position: relative;
`;

const Credits = styled.div`
    ${typography.label};
    color: ${color("dark")};
    margin-bottom: 48px;
    text-transform: none;

    @media screen and (min-width: 768px) {
        margin-bottom: 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }

    a {
        color: inherit;
        transition: color 0.2s ease;

        &:hover {
            color: ${color("dark blue")};
        }
    }
`;

const ArticleCategories = styled.div`
    margin: 0 -10px 40px;
    background-color: ${color("white")};

    @media screen and (min-width: 768px) {
        margin: 0 -12px 56px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 64px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 72px;
    }
`;
const CategoryTitle = styled.h3`
    ${typography.label};
    text-transform: uppercase;
    color: ${color("dark")};
    line-height: 1;
    display: inline-block;
    border-right: 1px solid ${color("dark")};
    padding: 0 10px;
    text-decoration: none;
    transition: color 0.2s;

    @media screen and (min-width: 768px) {
        padding: 0 12px;
    }

    &:last-child {
        border-right: none;
    }
`;
const Category = styled(CustomLink)`
    ${typography.label};
    text-transform: uppercase;
    color: ${color("dark")};
    line-height: 1;
    display: inline-block;
    border-right: 1px solid ${color("dark")};
    padding: 0 10px;
    text-decoration: none;
    transition: color 0.2s;

    @media screen and (min-width: 768px) {
        padding: 0 12px;
    }

    &:last-child {
        border-right: none;
    }

    &:hover {
        text-decoration: underline;
        color: ${color("dark blue")};
    }
`;

const ArticleTags = styled.div`
    background-color: ${color("white")};
    border-left: 1px solid ${color("dark")};
    padding-left: 10px;
    margin-bottom: 40px;

    @media screen and (min-width: 768px) {
        position: absolute;
        left: 100%;
        top: 0;
        margin-left: 10px;
        width: 20px;
        margin-bottom: 0;
    }

    @media screen and (min-width: 1200px) {
        display: block;
        margin-left: 30px;
        width: 120px;
    }

    @media screen and (min-width: 1800px) {
        display: block;
        margin-left: 58px;
        width: 140px;
    }
`;
const ArticleTagsTitle = styled.h3`
    ${typography.label};
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 4px;
`;
const Tag = styled(CustomLink)`
    ${typography.label};
    text-transform: none;
    color: ${color("dark")};
    display: inline-block;
    text-decoration: none;
    transition: color 0.2s;
    line-height: 1.3;

    &:last-child {
        border-right: none;

        &::after {
            content: "";
            display: none;
        }
    }

    &:hover {
        text-decoration: underline;
        color: ${color("dark blue")};
    }

    &::after {
        content: ",";
        padding-right: 5px;
    }

    @media screen and (min-width: 768px) {
        ${typography.labelSmall};
    }
`;

const BodyInsight = ({
    type,
    title,
    pageUrl,
    theme,
    slices = [],
    categories,
    tags,
    author,
    credits,
}) => {
    const {
        site: {
            siteMetadata: { siteUrl },
        },
    } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
        `
    );

    const currentUrl = encodeURIComponent(siteUrl + pageUrl);
    return (
        <Section
            bg="white"
            fg="dark"
            hr="grey"
            theme={theme}
            paddingS="40px 0 64px 0"
            paddingM="56px 0 72px 0"
            paddingL="64px 0 105px 0"
            paddingXL=" 72px 0 120px 0"
            paddingXXL="72px 0 145px 0"
        >
            <Container type="article-narrow">
                <RelativeWrapper>
                    {categories && categories.length ? (
                        <ArticleCategories>
                            <CategoryTitle>
                                <strong>Category</strong>
                            </CategoryTitle>
                            {categories.map(category => {
                                if (category.category.document) {
                                    return (
                                        <Category
                                            key={category.category.document.uid}
                                            url={`/insights/category-${category.category.document.uid}`}
                                            internal={true}
                                        >
                                            {
                                                category.category.document.data
                                                    .title
                                            }
                                        </Category>
                                    );
                                }
                                return null;
                            })}
                        </ArticleCategories>
                    ) : null}
                </RelativeWrapper>
                <RelativeWrapper>
                    {tags && tags.length ? (
                        <ArticleTags>
                            <ArticleTagsTitle>Topics</ArticleTagsTitle>
                            {tags.map(tag => {
                                return (
                                    <Tag
                                        key={tag.slug}
                                        url={`/insights/tag-${tag.slug}`}
                                        internal={true}
                                    >
                                        {tag.title}
                                    </Tag>
                                );
                            })}
                        </ArticleTags>
                    ) : null}
                    <div>
                        {slices && slices.length
                            ? slices.map(slice => {
                                  switch (slice.__typename) {
                                      case "PrismicInsightBodyRichText":
                                          return (
                                              <Standard
                                                  key={slice.id}
                                                  dangerouslySetInnerHTML={{
                                                      __html:
                                                          slice.primary.content
                                                              .html,
                                                  }}
                                              />
                                          );
                                      case "PrismicInsightBodyLead":
                                          return (
                                              <Lead
                                                  key={slice.id}
                                                  dangerouslySetInnerHTML={{
                                                      __html:
                                                          slice.primary.content
                                                              .html,
                                                  }}
                                              />
                                          );
                                      case "PrismicInsightBodyImage":
                                          return (
                                              <ContentImage
                                                  key={slice.id}
                                                  fluid={
                                                      slice.primary.image.fluid
                                                  }
                                                  alt={slice.primary.image.alt}
                                                  caption={
                                                      slice.primary.caption1
                                                          .html
                                                  }
                                                  width={
                                                      slice.primary.image
                                                          .dimensions.width
                                                  }
                                              />
                                          );
                                      case "PrismicInsightBodyVideo":
                                          const videoRatio =
                                              parseInt(
                                                  slice.primary.video.height
                                              ) /
                                              parseInt(
                                                  slice.primary.video.width
                                              );
                                          return (
                                              <IframeHtml
                                                  key={slice.id}
                                                  content={
                                                      slice.primary.video.html.replace("?feature=oembed", "?feature=oembed&enablejsapi=1")
                                                  }
                                                  ratio={
                                                      isNaN(videoRatio)
                                                          ? null
                                                          : videoRatio
                                                  }
                                                  caption={
                                                      slice.primary.caption1
                                                          .html
                                                  }
                                              />
                                          );
                                      case "PrismicInsightBodyQuote":
                                          return (
                                              <Quote
                                                  key={slice.id}
                                                  dangerouslySetInnerHTML={{
                                                      __html:
                                                          slice.primary.quote
                                                              .html,
                                                  }}
                                              />
                                          );
                                      case "PrismicInsightBodyIframeEmbed":
                                          const iframeRatio =
                                              parseInt(slice.primary.height) /
                                              parseInt(slice.primary.width);
                                          return (
                                              <IframeHtml
                                                  key={slice.id}
                                                  content={slice.primary.embed}
                                                  ratio={
                                                      isNaN(iframeRatio)
                                                          ? null
                                                          : iframeRatio
                                                  }
                                              />
                                          );
                                      case "PrismicInsightBodyNewsletterCta":
                                          return (
                                              <CTA
                                                  title={slice.primary.title1}
                                                  location={
                                                      slice.primary.location_key
                                                  }
                                                  key={slice.id}
                                              />
                                          );
                                  }
                              })
                            : null}
                    </div>
                </RelativeWrapper>
                {credits ? (
                    <Credits dangerouslySetInnerHTML={{ __html: credits }} />
                ) : null}
                <SocialLinks>
                    <HoverDropdown
                        title={type ? `Share this ${type}` : null}
                        links={[
                            {
                                name: "Email",
                                url: `mailto:?subject=${encodeURIComponent(
                                    title
                                )}&body=${currentUrl}`,
                                key: "email",
                                email: true,
                            },
                            {
                                name: "Facebook",
                                url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
                                key: "facebook",
                                newWindow: true,
                            },
                            {
                                name: "Twitter",
                                url: `http://twitter.com/share?text=${encodeURIComponent(
                                    title
                                )}&url=${currentUrl}`,
                                key: "twitter",
                                newWindow: true,
                            },
                            {
                                name: "LinkedIn",
                                url: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`,
                                key: "linkedin",
                                newWindow: true,
                            },
                        ]}
                        width="90%"
                        horizontal={true}
                    />
                </SocialLinks>
                {author ? <AuthorBio author={author} /> : null}
            </Container>
        </Section>
    );
};

export default BodyInsight;
