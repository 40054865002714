import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Form from "./Form";
import typography from "../../styles/typography";
import color from "../../styles/colors";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const Outer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    overflow: auto;
    padding: 20px;
    display: flex;
    transition: opacity 0.2s ease;
    opacity: ${({ status }) =>
        status === "exited" || status === "exiting" ? 0 : 1};
`;
const Overlay = styled.div`
    z-index: 1;
    background-color: rgba(0, 20, 40, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
const Container = styled.div`
    margin: auto;
    width: 1392px;
    max-width: 100%;
    background-color: white;
    display: flex;
    position: relative;
    z-index: 3;
    padding: 10px;
`;
const TitleCard = styled.div`
    flex-grow: 1;
    background: linear-gradient(
        71.97deg,
        #a9c4e7 -42.4%,
        #a9c4e7 13.94%,
        #ffd0c0 102.21%
    );
    display: none;
    align-items: flex-end;
    padding: 20px;

    @media screen and (min-width: 768px) {
        display: flex;
    }
`;
const TitleText = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-family: Faktum, sans-serif;
    font-size: 40px;
    @media screen and (min-width: 768px) {
        font-size: 40px;
    }

    @media screen and (min-width: 1024px) {
        font-size: 60px;
    }

    @media screen and (min-width: 1200px) {
        font-size: 70px;
    }

    @media screen and (min-width: 1800px) {
        font-size: 80px;
    }
`;
const FormCard = styled.div`
    width: 686px;
    padding: 65px 20px 20px;
    @media screen and (min-width: 768px) {
        padding: 103px 48px 10px 58px;
    }
`;
const Close = styled.button`
    ${typography.label};
    font-weight: 600;
    position: absolute;
    top: 38px;
    left: 30px;
    border: none;
    background: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: color 0.2s ease;
    path {
        transition: stroke 0.2s ease;
    }
    &:hover {
        color: ${color("dark blue")};
        path {
            stroke: ${color("dark blue")};
        }
    }
    &:focus {
        outline: 0;
        color: ${color("dark blue")};
        path {
            stroke: ${color("dark blue")};
        }
    }
    svg {
        margin-right: 10px;
    }
`;

const Modal = ({ modalOpen = false, closeModal = () => {}, status }) => {
    const ref = useRef();
    useEffect(() => {
        let localRef = ref.current;
        if (status === "exiting") {
            enableBodyScroll(localRef);
        }
        if (status === "entering") {
            disableBodyScroll(localRef);
        }
    }, [status]);
    return (
        <Outer status={status} ref={ref}>
            <Overlay onClick={closeModal} />
            <Container>
                <Close onClick={closeModal}>
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M1 1L8 8M15 15L8 8M8 8L15 1M8 8L1 15"
                            stroke="#001428"
                            strokeWidth="2"
                            strokeLinejoin="round"
                        />
                    </svg>
                    <span>Close</span>
                </Close>
                <TitleCard>
                    <TitleText>
                        News,
                        <br />
                        trends,
                        <br />
                        insights.
                    </TitleText>
                </TitleCard>
                <FormCard>
                    <Form closeModal={closeModal} modalOpen={modalOpen} />
                </FormCard>
            </Container>
        </Outer>
    );
};

export default Modal;
