import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import color from "../../styles/colors";
import Container from "../Context/Container";
import Section from "../Context/Section";
import HoverDropdown from "../Elements/HoverDropdown";
import CustomLink from "../Elements/CustomLink";
import ChevronLeftThinSvg from "../SVGs/ChevronLeftThinSvg";
import { graphql, useStaticQuery } from "gatsby";

const BackLink = styled.div`
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
        margin-bottom: 42px;
    }

    a {
        color: ${color("dark")};
        text-decoration: none;
        ${typography.label};
        transition: color 0.2s;

        svg {
            stroke: ${color("dark")};
            height: 0.8em;
            position: relative;
            top: 1px;
            margin-right: 11px;
            transition: stroke 0.2s;
        }

        &:hover {
            color: ${color("dark blue")};

            svg {
                stroke: ${color("dark blue")};
            }
        }
    }
`;

const TitleWrapper = styled.div`
    margin-bottom: 32px;
    ${props => props.isArticle ? css`
        display: flex;
        flex-direction: column-reverse;
    ` : null}
    

    @media screen and (min-width: 768px) {
        margin-bottom: 42px;
        ${props => props.isArticle ? css`
            display: block;
        ` : null}
    }
`;

const Title = styled.h1`
    ${typography.h2Hero};
    margin-bottom: 0;
`;

const MetaWrapper = styled.div`
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-start;
    }
`;

const PublishDate = styled.p`
    ${typography.tileMeta};
    text-transform: uppercase;
    margin-bottom: 0;
`;

const Author = styled.p`
    ${typography.tileMeta};
    text-transform: uppercase;
    position: relative;
    font-weight: 600;
`;

const Line = styled.hr`
    text-decoration: none;
    color: inherit;
    width: 15px;
    border-right: none;
    border-left: none;
    border-bottom: none;
    border-top-width: 1px;
    margin: 0 10px;

    @media screen and (min-width: 768px) {
        width: 34px;
        margin: 0 12px;
    }
`;

const Meta = styled.div`
    display: flex;
    align-items: center;
    background-color: ${color("green")};
    padding: 9px 20px;
    margin: 0 -20px;

    @media screen and (min-width: 600px) {
        padding-left: 48px;
        padding-right: 48px;
        margin: 0 -48px;
    }

    @media screen and (min-width: 768px) {
        margin-top: 56px;
        padding: 8px 24px;
        margin: 0;
    }
`;

const SocialLinks = styled.div`
    margin-bottom: 32px;

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
    }
`;

const HeroInsightsArticle = ({title, date, author, pageUrl, theme}) => {
    const { site: {siteMetadata: {siteUrl}} } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        siteUrl
                    }
                }
            }
        `
    )

    const currentUrl = encodeURIComponent(siteUrl + pageUrl);
    return (
        <Section paddingS="180px 0 0" paddingM="200px 0 0" paddingL="225px 0 0" paddingXL="248px 0 0" paddingXXL="248px 0 0" bg="white" fg="dark" theme={theme} flexGrow="1">
            <Container>
                    <BackLink>
                        <CustomLink url="/insights" internal={true}>
                            <ChevronLeftThinSvg />Back to <strong>Insights</strong>
                        </CustomLink>
                    </BackLink>
                    <TitleWrapper isArticle={date && author}>
                        <Title>
                            {title}
                        </Title>
                    </TitleWrapper>
                <MetaWrapper>
                    <SocialLinks>
                        <HoverDropdown
                            title="Share this article"
                            links={[
                                {
                                    name: "Email",
                                    url: `mailto:?subject=${encodeURIComponent(title)}&body=${currentUrl}`,
                                    key: "email",
                                    email: true
                                },
                                {
                                    name: "Facebook",
                                    url: `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`,
                                    key: "facebook",
                                    newWindow: true
                                },
                                {
                                    name: "Twitter",
                                    url: `http://twitter.com/share?text=${encodeURIComponent(title)}&url=${currentUrl}`,
                                    key: "twitter",
                                    newWindow: true
                                },
                                {
                                    name: "LinkedIn",
                                    url: `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`,
                                    key: "linkedin",
                                    newWindow: true
                                }
                            ]}
                            width="90%"
                            small={true}
                        />
                    </SocialLinks>
                    {date && author ? (
                        <Meta>
                            <PublishDate>{date}</PublishDate>
                            <Line />
                            <Author>{author}</Author>
                        </Meta>
                    ) : null}
                </MetaWrapper>
            </Container>
        </Section>
    );
};

export default HeroInsightsArticle;
