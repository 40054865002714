import React from "react";

const ChevronLeftThinSvg = () => {
    return (
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.07107 0.928932L2 8L9.07107 15.0711" strokeWidth="2"/>
        </svg>        
    );
};

export default ChevronLeftThinSvg;